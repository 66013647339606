if (window.innerWidth <= 991) {
  $(".carrossel-products__content").slick({
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  });
}
